// src/pages/CreateProjectScreen.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const CreateProjectScreen = () => {
  const [projectName, setProjectName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { user } = useUser();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!projectName.trim()) {
      setMessage('Please enter a project name.');
      return;
    }

    setIsLoading(true);
    setMessage('');

    try {
      const response = await fetch(`${API_BASE_URL}/project/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: user.user_id, projectName }),
      });

      if (!response.ok) {
        throw new Error('Failed to create project');
      }

      const data = await response.json();
      setMessage('Project creation successful!');
      setProjectName(''); // Clear the input field after successful creation
      
      // Navigate to the ProjectHomeScreen of the newly created project
      navigate(`/project/${data.project_id}`);
    } catch (error) {
      setMessage('Failed to create project. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h1 style={styles.formTitle}>Create New Project</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="projectName" style={styles.label}>Project Name</label>
            <input
              id="projectName"
              style={styles.formInput}
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Enter Project Name"
            />
          </div>
          {message && <div style={styles.error}>{message}</div>}
          <button 
            type="submit" 
            style={{...styles.button, ...(isLoading ? styles.disabledButton : {})}}
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'Create Project'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateProjectScreen;
// src/pages/ProjectInfoUploadScreen.js

import React, { useState } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { styles } from '../styles';
import { Upload, File, X } from 'lucide-react';
import { useUser } from '../UserContext';
import { API_BASE_URL } from '../config';

const ProjectInfoUploadScreen = () => {
  const { contextId } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState('');
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const infoId = searchParams.get('infoId');

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      if (file.type !== 'application/pdf') {
        setError('Please select a PDF file');
        setSelectedFile(null);
        event.target.value = ''; // Reset file input
        return;
      }

      // Check file size (limit to 10MB)
      if (file.size > 10 * 1024 * 1024) {
        setError('File size must be less than 10MB');
        setSelectedFile(null);
        event.target.value = ''; // Reset file input
        return;
      }

      setError(null);
      setSelectedFile(file);
    }
  };

  const extractText = async (attachmentId) => {
    try {
      setUploadProgress('Extracting text from PDF...');
      const response = await fetch(
        `${API_BASE_URL}/upload-context/pdf-to-text/${contextId}/information/${infoId}/attachment/${attachmentId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: user.user_id
          }),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Text extraction failed');
      }

      const data = await response.json();
      console.log('Text extraction completed:', data.preview);
      setUploadProgress('Text extraction complete');
    } catch (err) {
      console.error('Error extracting text:', err);
      setError('Text extraction failed, but file was uploaded successfully');
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !infoId) return;

    setIsUploading(true);
    setError(null);
    setUploadProgress('Uploading PDF...');

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('user_id', user.user_id);
      formData.append('info_id', infoId);
      formData.append('info_name', selectedFile.name.replace('.pdf', '')); 

      const response = await fetch(`${API_BASE_URL}/upload-context/upload-pdf/${contextId}`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Upload failed');
      }

      const data = await response.json();
      
      // Extract text after successful upload
      await extractText(data.attachment_id);

      // Navigate back to context information page
      navigate(`/context-information/${contextId}?infoId=${infoId}`);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'Failed to upload file. Please try again.');
    } finally {
      setIsUploading(false);
      setUploadProgress('');
    }
  };

  const clearSelection = () => {
    setSelectedFile(null);
    setError(null);
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/context-information/${contextId}?infoId=${infoId}`} style={styles.link}>
          &lt;&lt; Back to Information
        </Link>
      </div>

      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Upload PDF Content
      </h1>

      <div style={styles.formGroup}>
        {/* Hidden file input */}
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          id="pdf-upload"
        />

        {/* Custom upload button */}
        {!selectedFile && (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '40px',
            border: '2px dashed #666',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
          onClick={() => document.getElementById('pdf-upload').click()}>
            <Upload size={48} style={{ marginBottom: '16px', color: '#666' }} />
            <p style={{ color: '#666', marginBottom: '8px' }}>
              Click to select a PDF file
            </p>
            <p style={{ color: '#888', fontSize: '0.8rem' }}>
              Maximum file size: 10MB
            </p>
          </div>
        )}

        {/* Error message */}
        {error && (
          <div style={{
            color: '#ff4444',
            textAlign: 'center',
            marginTop: '16px',
            padding: '8px',
            backgroundColor: 'rgba(255,68,68,0.1)',
            borderRadius: '4px'
          }}>
            {error}
          </div>
        )}

        {/* Selected file details */}
        {selectedFile && (
          <div style={{
            marginTop: '20px',
            padding: '16px',
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '8px',
            position: 'relative'
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px'
            }}>
              <File size={24} />
              <div style={{ flex: 1 }}>
                <p style={{ marginBottom: '4px', fontWeight: 'bold' }}>
                  {selectedFile.name}
                </p>
                <p style={{ fontSize: '0.9rem', color: '#888' }}>
                  {formatFileSize(selectedFile.size)}
                </p>
              </div>
              <button
                onClick={clearSelection}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '4px',
                  color: '#666',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <X size={20} />
              </button>
            </div>
          </div>
        )}

        {/* Upload progress indicator */}
        {uploadProgress && (
          <p style={{
            marginTop: '10px',
            color: '#666',
            textAlign: 'center',
            fontSize: '0.9rem'
          }}>
            {uploadProgress}
          </p>
        )}

        {/* Upload button */}
        {selectedFile && (
          <button
            onClick={handleUpload}
            disabled={isUploading}
            style={{
              ...styles.button,
              marginTop: '20px',
              width: '100%',
              opacity: isUploading ? '0.7' : '1',
              cursor: isUploading ? 'not-allowed' : 'pointer'
            }}
          >
            {isUploading ? 'Processing...' : 'Upload PDF'}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProjectInfoUploadScreen;
// src/pages/ContextInformationScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Paperclip, File } from 'lucide-react';

const ContextInformationScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [infoName, setInfoName] = useState('');
  const [information, setInformation] = useState('');
  const [contextName, setContextName] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [savedInfoId, setSavedInfoId] = useState(null);  // Track saved info ID
  const { contextId } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const infoId = searchParams.get('infoId');

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchContextDetails();
      if (infoId) {
        setSavedInfoId(infoId);  // Set saved ID when loading existing info
        fetchInformationDetails();
        fetchAttachments();
      } else {
        setIsLoading(false);
      }
    } else {
      navigate('/login');
    }
  }, [contextId, infoId, user.isAuthenticated, navigate]);

  const fetchContextDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/project-context/${contextId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch context details');
      }

      const data = await response.json();
      setContextName(data.contextItem.name || 'Unnamed Context');
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    }
  };

  const fetchInformationDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/project-context/${contextId}/information/${infoId}?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch information details');
      }

      const data = await response.json();
      setInfoName(data.information.name);
      setInformation(data.information.information);
      setIsLoading(false);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
      setIsLoading(false);
    }
  };

  const fetchAttachments = async () => {
    try {
      console.log('Fetching attachments for info:', infoId);
      const response = await fetch(
        `${API_BASE_URL}/upload-context/${contextId}/information/${infoId}/attachments?user_id=${user.user_id}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch attachments');
      }
  
      const data = await response.json();
      console.log('Received attachments:', data);
      setAttachments(data.attachments || []);
    } catch (err) {
      console.error('Error fetching attachments:', err);
    }
  };

  const handleSave = async () => {
    try {
      const url = infoId 
        ? `${API_BASE_URL}/project-context/${contextId}/information/${infoId}`
        : `${API_BASE_URL}/project-context/${contextId}/add-information`;
      
      const response = await fetch(url, {
        method: infoId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          info_name: infoName,
          information: information
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save information');
      }

      const data = await response.json();
      
      // If this was a new information save, set the saved ID
      if (!infoId && data.information_id) {
        setSavedInfoId(data.information_id);
        // Trigger attachment fetch after initial save
        fetchAttachments();
      }

      // Don't navigate away immediately if this was the initial save
      if (infoId) {
        navigate(`/project-context/${contextId}`);
      }

    } catch (err) {
      console.error('Error:', err);
      setError('Failed to save information. Please try again.');
    }
  };

  const handleDelete = async () => {
    if (!infoId || !window.confirm('Are you sure you want to delete this information?')) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/project-context/${contextId}/information/${infoId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete information');
      }

      navigate(`/project-context/${contextId}`);
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to delete information. Please try again.');
    }
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/project-context/${contextId}`} style={styles.link}>
          &lt;&lt; Back to Context
        </Link>
      </div>

      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        {infoId ? 'Edit' : 'Add'} Information for {contextName}
      </h1>

      <div style={styles.formGroup}>
        <label style={styles.label}>Context Information Name:</label>
        <input
          type="text"
          value={infoName}
          onChange={(e) => setInfoName(e.target.value)}
          style={styles.formInput}
          placeholder="Enter information name (required)"
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Context Information:</label>
        <textarea
          value={information}
          onChange={(e) => setInformation(e.target.value)}
          style={styles.textarea}
          placeholder="Enter information (required)"
          rows={10}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Uploaded Content:</label>
        {attachments.length > 0 ? (
          <ul style={{
            listStyle: 'none',
            padding: 0,
            margin: '8px 0',
          }}>
            {attachments.map((attachment, index) => (
              <li 
                key={attachment.id}
                style={{
                  marginBottom: '8px',
                  color: '#666',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <File size={16} style={{ marginRight: '8px' }} />
                <Link 
                  to={`/view-attachment/${contextId}?infoId=${infoId || savedInfoId}&attachmentId=${attachment.id}`}
                  style={{
                    ...styles.link,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#666',
                  }}
                >
                  {attachment.name}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p style={{ color: '#666', margin: '8px 0' }}>
            {(infoId || savedInfoId) ? 'No files uploaded yet' : 'Files may be uploaded after initial save'}
          </p>
        )}
        
        {/* Show upload link if we have either an infoId from URL or a saved ID */}
        {(infoId || savedInfoId) && (
          <Link 
            to={`/context-information/${contextId}/upload?infoId=${infoId || savedInfoId}`}
            style={{
              ...styles.link,
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              fontSize: '0.9rem'
            }}
          >
            <Paperclip size={16} style={{marginRight: '8px'}} />
            Upload content
          </Link>
        )}
      </div>

      <div style={styles.projectActions}>
        <button
          onClick={handleSave}
          style={styles.button}
          disabled={!infoName.trim() || !information.trim()}
        >
          Save Information
        </button>
        {(infoId || savedInfoId) && (
          <button
            onClick={handleDelete}
            style={{...styles.button, backgroundColor: '#ff4444', marginLeft: '10px'}}
          >
            Delete Information
          </button>
        )}
      </div>

      {/* Add a "return to context" button if we've just saved */}
      {savedInfoId && !infoId && (
        <div style={{...styles.projectActions, marginTop: '20px'}}>
          <Link 
            to={`/project-context/${contextId}`}
            style={{
              ...styles.button,
              textDecoration: 'none',
              textAlign: 'center',
              backgroundColor: '#6c757d'
            }}
          >
            Return to Context
          </Link>
        </div>
      )}
    </div>
  );
};

export default ContextInformationScreen;
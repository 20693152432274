// src/pages/UserLoginScreen.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { Eye, EyeOff } from 'lucide-react';
import { API_BASE_URL } from '../config';

function PasswordInput({ value, onChange, required }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={styles.inputContainer}>
      <input
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        style={styles.formInput}
        required={required}
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        style={styles.eyeIcon}
      >
        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
  );
}

function UserLoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { updateUser } = useUser();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        setError('Your email address is not verified. Please check your inbox.');
        return;
      }

      const response = await fetch(`${API_BASE_URL}/auth/user-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: user.uid }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user details');
      }

      const data = await response.json();

      updateUser({
        isAuthenticated: true,
        userName: data.userName,
        email: data.email,
        user_id: user.uid,
      });

      // Change this line to redirect to the ProjectListScreen
      navigate('/projectlist');

    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'An error occurred during login. Please try again.');
    }
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.sectionHeader}>Login</h1>
        <form onSubmit={handleLogin} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="email" style={styles.label}>Email:</label>
            <div style={styles.inputContainer}>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.formInput}
                required
              />
            </div>
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="password" style={styles.label}>Password:</label>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
          </div>
          {error && <p style={styles.error}>{error}</p>}
          <button type="submit" style={styles.button}>Login</button>
        </form>
        <p>
          New user? <Link to="/register" style={styles.link}>Register here</Link>
        </p>
        <p>
          Forgot password? <Link to="/passwordresetrequest" style={styles.link}>Reset password here</Link>
        </p>
      </div>
    </div>
  );
}

export default UserLoginScreen;
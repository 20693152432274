// src/pages/ProjectHomeScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Pencil, PlusCircle } from 'lucide-react';

const ProjectHomeScreen = () => {
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [permissionLevel, setPermissionLevel] = useState(null);
  const [projectOwnerId, setProjectOwnerId] = useState(null);
  const [stories, setStories] = useState([]);
  const [contextItems, setContextItems] = useState([]);
  const [characters, setCharacters] = useState([]);
  const { user } = useUser();
  const { projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const projectIdFromState = location.state?.projectId;

  useEffect(() => {
    if (user.isAuthenticated) {
      checkPermissionAndFetchProject();
      fetchContextItems();
      fetchCharacters();
    } else {
      navigate('/login');
    }
  }, [projectId, projectIdFromState, user.isAuthenticated, navigate]);

  const checkPermissionAndFetchProject = async () => {
    const id = projectId || projectIdFromState;
    if (!user.user_id || !id) return;

    try {
      const response = await fetch(`${API_BASE_URL}/project/${id}/check-and-fetch?user_id=${user.user_id}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch project');
      }

      const data = await response.json();
      setProject(data.project);
      setPermissionLevel(data.permissionLevel);
      setProjectOwnerId(data.projectOwnerId);
      setName(data.project.name || '');
      setDescription(data.project.description || '');

      // Fetch stories for the project
      const storiesResponse = await fetch(`${API_BASE_URL}/story/list?project_id=${id}&user_id=${user.user_id}`);
      if (!storiesResponse.ok) {
        throw new Error('Failed to fetch stories');
      }
      const storiesData = await storiesResponse.json();
      setStories(storiesData.stories);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContextItems = async () => {
    const id = projectId || projectIdFromState;
    if (!user.user_id || !id) return;

    try {
      const response = await fetch(`${API_BASE_URL}/project-context/list?project_id=${id}&user_id=${user.user_id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch context items');
      }
      const data = await response.json();
      setContextItems(data.contextItems);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    }
  };

  const fetchCharacters = async () => {
    const id = projectId || projectIdFromState;
    if (!user.user_id || !id) return;

    try {
      const response = await fetch(`${API_BASE_URL}/character/list?project_id=${id}&user_id=${user.user_id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch characters');
      }
      const data = await response.json();
      setCharacters(data.characters);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    }
  };

  const handleDeleteProject = async () => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        const response = await fetch(`${API_BASE_URL}/project/delete`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: user.user_id, project_id: project.id }),
        });

        if (!response.ok) {
          throw new Error('Failed to delete project');
        }

        navigate('/projectlist');
      } catch (err) {
        setError('Failed to delete project. Please try again.');
      }
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleNameSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/project/${project.id}/update-name`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id, 
          project_owner_id: projectOwnerId,
          name 
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update name');
      }

      setProject({ ...project, name });
      setIsEditingName(false);
    } catch (err) {
      console.error('Error updating name:', err);
      setError('Failed to update name. Please try again.');
    }
  };

  const handleDescriptionSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/project/${project.id}/update-description`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id, 
          project_owner_id: projectOwnerId,
          description 
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update description');
      }

      setProject({ ...project, description });
      setIsEditingDescription(false);
    } catch (err) {
      console.error('Error updating description:', err);
      setError('Failed to update description. Please try again.');
    }
  };

  const handleShareProject = () => {
    navigate(`/share/${project.id}`, { state: { projectName: project.name } });
  };

  if (isLoading) return <div style={styles.container}>Loading project...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!project) return <div style={styles.container}>Project not found.</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to="/projectlist" style={styles.link}>
          &lt;&lt; Back to Projects List
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        {project.name || 'Unnamed Project'}
      </h1>

      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          {permissionLevel === 'owner' && (
            <button
              onClick={() => setIsEditingName(!isEditingName)}
              style={{...styles.button, ...styles.editButton}}
            >
              <Pencil size={16} />
            </button>
          )}
          <label style={styles.label}>Project Name:</label>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          {isEditingName && permissionLevel === 'owner' ? (
            <>
              <input
                value={name}
                onChange={handleNameChange}
                style={{...styles.formInput, textAlign: 'left'}}
                placeholder="Enter project name"
              />
              <button onClick={handleNameSave} style={{...styles.button, marginTop: '10px'}}>
                Save
              </button>
            </>
          ) : (
            <p style={{...styles.projectName, textAlign: 'left'}}>
              {project.name || 'Unnamed Project'}
            </p>
          )}
        </div>
      </div>

      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          {(permissionLevel === 'owner' || permissionLevel === 'editor') && (
            <button
              onClick={() => setIsEditingDescription(!isEditingDescription)}
              style={{...styles.button, ...styles.editButton}}
            >
              <Pencil size={16} />
            </button>
          )}
          <label style={styles.label}>Project Description:</label>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          {isEditingDescription && (permissionLevel === 'owner' || permissionLevel === 'editor') ? (
            <>
              <textarea
                value={description}
                onChange={handleDescriptionChange}
                style={{...styles.textarea, textAlign: 'left'}}
                placeholder="Enter project description"
              />
              <button onClick={handleDescriptionSave} style={{...styles.button, marginTop: '10px'}}>
                Save
              </button>
            </>
          ) : (
            <p style={{...styles.projectDescription, textAlign: 'left'}}>
              {project.description || 'No description available.'}
            </p>
          )}
        </div>
      </div>

      <div style={{...styles.formGroup, alignItems: 'flex-start'}}>
        <label style={{...styles.label, marginBottom: '10px'}}>Project Context:</label>
        {contextItems.length > 0 ? (
          <ul style={{
            ...styles.storiesList,
            padding: 0,
            margin: 0,
            listStyleType: 'none',
          }}>
            {contextItems.map((item) => (
              <li key={item.id} style={{
                marginBottom: '10px',
              }}>
                <Link 
                  to={`/project-context/${item.id}`} 
                  style={{
                    ...styles.storyLink,
                    display: 'block',
                    textAlign: 'left',
                  }}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p style={{textAlign: 'left'}}>No context items yet.</p>
        )}
        {(permissionLevel === 'owner' || permissionLevel === 'editor') && (
          <Link 
            to={`/new-project-context/${project.id}`} 
            style={{
              ...styles.link,
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#007BFF',
              marginTop: '10px',
            }}
          >
            <PlusCircle size={24} style={{marginRight: '10px'}} />
            <span>Add New Context</span>
          </Link>
        )}
      </div>

      <div style={{...styles.formGroup, alignItems: 'flex-start'}}>
        <label style={{...styles.label, marginBottom: '10px'}}>Characters:</label>
        {characters.length > 0 ? (
          <ul style={{
            ...styles.storiesList,
            padding: 0,
            margin: 0,
            listStyleType: 'none',
          }}>
            {characters.map((character) => (
              <li key={character.id} style={{
                marginBottom: '10px',
              }}>
                <Link 
                  to={`/character/${character.id}`} 
                  style={{
                    ...styles.storyLink,
                    display: 'block',
                    textAlign: 'left',
                  }}
                >
                  {character.name}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p style={{textAlign: 'left'}}>No characters yet.</p>
        )}
        {(permissionLevel === 'owner' || permissionLevel === 'editor') && (
          <Link 
            to={`/new-character/${project.id}`} 
            style={{
              ...styles.link,
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#007BFF',
              marginTop: '10px',
            }}
          >
            <PlusCircle size={24} style={{marginRight: '10px'}} />
            <span>Add New Character</span>
          </Link>
        )}
      </div>

      <div style={{...styles.formGroup, alignItems: 'flex-start'}}>
        <label style={{...styles.label, marginBottom: '10px'}}>Stories:</label>
        {stories.length > 0 ? (
          <ul style={{
            ...styles.storiesList,
            padding: 0,
            margin: 0,
            listStyleType: 'none',
          }}>
            {stories.map((story) => (
              <li key={story.id} style={{
                marginBottom: '10px',
              }}>
                <Link 
                  to={`/story/${story.id}`} 
                  style={{
                    ...styles.storyLink,
                    display: 'block',
                    textAlign: 'left',
                  }}
                >
                  {story.title}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p style={{textAlign: 'left'}}>No stories yet.</p>
        )}
        {(permissionLevel === 'owner' || permissionLevel === 'editor') && (
          <Link 
            to={`/new-story/${project.id}`} 
            style={{
              ...styles.link,
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#007BFF',
              marginTop: '10px',
            }}
          >
            <PlusCircle size={24} style={{marginRight: '10px'}} />
            <span>Add New Story</span>
          </Link>
        )}
      </div>

      <div style={styles.projectActions}>
        <button 
          style={{...styles.button, backgroundColor: '#007BFF', marginRight: '10px'}}
          onClick={handleShareProject}
        >
          Share Project
        </button>
        {permissionLevel === 'owner' && (
          <button 
            style={{...styles.button, backgroundColor: '#ff4444'}}
            onClick={handleDeleteProject}
          >
            Delete Project
          </button>
        )}
      </div>
    </div>
  );
};

export default ProjectHomeScreen;
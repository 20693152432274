// src/pages/DeleteAccountScreen.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { styles } from '../styles';
import { useUser } from '../UserContext';
import { EmailAuthProvider, reauthenticateWithCredential, deleteUser } from "firebase/auth";
import { Eye, EyeOff } from 'lucide-react';
import { API_BASE_URL } from '../config';

function PasswordInput({ value, onChange, required }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={styles.inputContainer}>
      <input
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        style={styles.formInput}
        required={required}
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        style={styles.eyeIcon}
      >
        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
  );
}

function DeleteAccountScreen() {
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('No');
  const [error, setError] = useState('');
  const [isDeleteButtonActive, setIsDeleteButtonActive] = useState(false);
  const { user } = useUser();
  const { updateUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    setIsDeleteButtonActive(confirmation === 'Yes' && password.length > 0);
  }, [confirmation, password]);

  if (!auth.currentUser) {
    navigate('/login');
    return null;
  }

  const handleDeleteAccount = async (e) => {
    e.preventDefault();

    if (!isDeleteButtonActive) {
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is currently signed in');
      }

      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      const idToken = await user.getIdToken(true);

      const response = await fetch(`${API_BASE_URL}/auth/delete-account`, {
        method: 'DELETE',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({ uid: user.uid }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete account on the server');
      }

      await deleteUser(user);

      updateUser({
        isAuthenticated: false,
        name: null,
        email: null,
        user_id: null,
      });

      alert('Account deleted successfully.');
      navigate('/logout');
    } catch (error) {
      console.error('Account deletion error:', error);
      setError(error.message || 'An error occurred during account deletion. Please try again.');
    }
  };

  const handleCancel = () => {
    navigate('/myaccount');
  };

  const deleteButtonStyle = {
    ...styles.button,
    ...(isDeleteButtonActive ? {} : styles.disabledButton),
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.sectionHeader}>Delete Account</h1>
        <p>Are you sure you want to delete the account for {user.email}?</p>
        <form onSubmit={handleDeleteAccount} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="confirmation" style={styles.label}>Confirmation:</label>
            <select
              id="confirmation"
              value={confirmation}
              onChange={(e) => setConfirmation(e.target.value)}
              style={styles.formInput}
              required
            >
              <option value="No">No, do not delete</option>
              <option value="Yes">Yes, delete this account</option>
            </select>
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="password" style={styles.label}>Enter password to delete:</label>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
          </div>
          {error && <p style={styles.error}>{error}</p>}
          <div style={styles.buttonContainer}>
            <button 
              type="submit" 
              style={deleteButtonStyle}
              disabled={!isDeleteButtonActive}
            >
              Delete Account
            </button>
            <button type="button" onClick={handleCancel} style={styles.cancelButton}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DeleteAccountScreen;
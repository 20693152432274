// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import getAuth

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYqdwwueiaFGdjz1VV4DSvSpbyzzzypH4",
  authDomain: "virtualstudio-f03d6.firebaseapp.com",
  projectId: "virtualstudio-f03d6",
  storageBucket: "virtualstudio-f03d6.appspot.com",
  messagingSenderId: "522077362550",
  appId: "1:522077362550:web:899ae768f2c67852c4c257",
  measurementId: "G-BZ0LZ2L0MK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Get the authentication object
const auth = getAuth(app); // Use getAuth to get the auth object

// Export the auth object
export { auth }; // Make the auth object available for import
// src/pages/ShareProjectScreen.js

import React, { useState, useEffect } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { BASE_URL } from '../config';
import { useUser } from '../UserContext';


const ShareProjectScreen = () => {
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inviteEmail, setInviteEmail] = useState('');
  const [invitePermission, setInvitePermission] = useState('editor');
  const [inviteError, setInviteError] = useState(null);
  const [confirmRemove, setConfirmRemove] = useState(null);
  const [removeOption, setRemoveOption] = useState('reassign');
  const [removalInfo, setRemovalInfo] = useState(null);
  const [userPermission, setUserPermission] = useState('viewer');
  const [projectName, setProjectName] = useState('');
  const { projectId } = useParams();
  const location = useLocation();
  const { user } = useUser();

  useEffect(() => {
    fetchProjectPermissions();
    fetchProjectName();
  }, [projectId]);

  const fetchProjectPermissions = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/share/project-permissions/${projectId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch project permissions');
      }
      const data = await response.json();
      const sortedPermissions = data.permissions.sort((a, b) => {
        if (a.permission === 'owner') return -1;
        if (b.permission === 'owner') return 1;
        return 0;
      });
      setPermissions(sortedPermissions);

      // Find the current user's permission
      const userPerm = sortedPermissions.find(p => p.email === user.email);
      if (userPerm) {
        setUserPermission(userPerm.permission);
        setInvitePermission(getDefaultInvitePermission(userPerm.permission));
      }
    } catch (err) {
      setError('Failed to load project permissions. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjectName = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/project/${projectId}/check-and-fetch?user_id=${user.user_id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch project details');
      }
      const data = await response.json();
      setProjectName(data.project.name);
    } catch (err) {
      console.error('Error fetching project name:', err);
    }
  };

  const getDefaultInvitePermission = (permission) => {
    switch (permission) {
      case 'owner':
      case 'editor':
        return 'editor';
      case 'commenter':
        return 'commenter';
      default:
        return 'viewer';
    }
  };

  const canEditPermission = (currentUserEmail, targetUserEmail) => {
    if (userPermission === 'owner') return true;
    if (currentUserEmail === targetUserEmail) return true;
    return checkInvitationChain(currentUserEmail, targetUserEmail);
  };

  const checkInvitationChain = (currentUserEmail, targetUserEmail) => {
    const targetUser = permissions.find(p => p.email === targetUserEmail);
    if (!targetUser) return false;
    if (targetUser.sharedby === currentUserEmail) return true;
    return checkInvitationChain(currentUserEmail, targetUser.sharedby);
  };

  const getPermissionOptions = (currentUserPermission, targetUserEmail) => {
    if (currentUserPermission === 'owner') {
      return ['editor', 'commenter', 'viewer', 'remove'];
    }
    if (targetUserEmail === user.email) {
      return [currentUserPermission, 'remove'];
    }
    const permissionLevels = ['viewer', 'commenter', 'editor'];
    const currentIndex = permissionLevels.indexOf(currentUserPermission);
    return permissionLevels.slice(0, currentIndex + 1).concat('remove');
  };

  const handleInvite = async () => {
    try {
      setInviteError(null);
      const response = await fetch(`${API_BASE_URL}/share/invite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          projectId, 
          email: inviteEmail,
          inviterEmail: user.email,
          permission: invitePermission
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send invite');
      }

      await fetchProjectPermissions();
      
      // Send invite email
      const sendEmailResponse = await fetch(`${API_BASE_URL}/share/send-invite-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          projectId,
          projectName,
          inviterEmail: user.email,
          inviteeEmail: inviteEmail,
          baseUrl: BASE_URL
        }),
      });

      if (!sendEmailResponse.ok) {
        console.error('Failed to send invite email');
      }

      setInviteEmail('');
      setInvitePermission(getDefaultInvitePermission(userPermission));
    } catch (err) {
      setInviteError(err.message);
    }
  };

  const handlePermissionChange = async (email, newPermission) => {
    if (newPermission === 'remove') {
      handleRemoveUser(email);
    } else {
      try {
        const response = await fetch(`${API_BASE_URL}/share/set-project-permission`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            projectId, 
            email, 
            permission: newPermission,
            sharedBy: user.email
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to update permission');
        }

        await fetchProjectPermissions();
      } catch (err) {
        setError('Failed to update permission. Please try again.');
      }
    }
  };

  const handleRemoveUser = (email) => {
    const targetUser = permissions.find(p => p.email === email);
    const higherLevelUser = permissions.find(p => p.email === targetUser.sharedby) || user;
    const invitees = permissions.filter(p => p.sharedby === email);
    setRemovalInfo({
      removedEmail: email,
      higherLevelEmail: higherLevelUser.email,
      inviteesCount: invitees.length
    });
    setConfirmRemove(email);
  };

  const confirmRemovePermission = async () => {
    if (!confirmRemove) return;

    try {
      const response = await fetch(`${API_BASE_URL}/share/remove-permission`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          projectId,
          email: confirmRemove,
          removeInvitees: removeOption === 'remove',
          newSharedBy: removeOption === 'reassign' ? removalInfo.higherLevelEmail : null,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to remove permission');
      }

      await fetchProjectPermissions();
      setConfirmRemove(null);
      setRemovalInfo(null);
      setRemoveOption('reassign');
    } catch (err) {
      setError('Failed to remove permission. Please try again.');
    }
  };

  if (isLoading) return <div style={styles.container}>Loading project details...</div>;
  if (error) return <div style={styles.container}>{error}</div>;

  return (
    <div style={{...styles.container, alignItems: 'flex-start'}}>
      <div style={styles.linkContainer}>
        <Link to={`/project/${projectId}`} style={styles.link}>
          &lt;&lt; Back to Project Home
        </Link>
      </div>
      <h1 style={styles.formTitle}>Share "{projectName}"</h1>
      <div style={styles.inviteContainer}>
        <input
          type="email"
          value={inviteEmail}
          onChange={(e) => setInviteEmail(e.target.value)}
          placeholder="Add another email to share"
          style={styles.inviteInput}
        />
        <select
          value={invitePermission}
          onChange={(e) => setInvitePermission(e.target.value)}
          style={styles.permissionSelect}
        >
          {getPermissionOptions(userPermission, '').filter(option => option !== 'remove').map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <button onClick={handleInvite} style={styles.inviteButton}>
          Send invite
        </button>
      </div>
      {inviteError && <div style={styles.error}>{inviteError}</div>}
      {permissions.length === 0 ? (
        <p style={styles.projectDescription}>No permissions found for this project.</p>
      ) : (
        <div style={styles.tableContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Email</th>
                <th style={styles.tableHeader}>Permission</th>
                <th style={styles.tableHeader}>Shared By</th>
              </tr>
            </thead>
            <tbody>
              {permissions.map((perm, index) => (
                <tr key={index}>
                  <td style={styles.tableCell}>{perm.email}</td>
                  <td style={styles.tableCell}>
                    {perm.permission === 'owner' ? (
                      <span>owner</span>
                    ) : canEditPermission(user.email, perm.email) ? (
                      <select
                        value={perm.permission}
                        onChange={(e) => handlePermissionChange(perm.email, e.target.value)}
                        style={styles.tableSelect}
                      >
                        {getPermissionOptions(userPermission, perm.email).map(option => (
                          <option key={option} value={option}>
                            {option === 'remove' ? 'Remove' : option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>{perm.permission}</span>
                    )}
                  </td>
                  <td style={styles.tableCell}>{perm.sharedby}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {confirmRemove && removalInfo && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <p style={styles.modalText}>Are you sure you want to remove access for {confirmRemove}?</p>
            {removalInfo.inviteesCount > 0 && (
              <div style={styles.radioGroup}>
                <label style={styles.radioLabel}>
                  <input
                    type="radio"
                    value="reassign"
                    checked={removeOption === 'reassign'}
                    onChange={() => setRemoveOption('reassign')}
                  />
                  {` Also reassign their ${removalInfo.inviteesCount} invitee${removalInfo.inviteesCount > 1 ? 's' : ''} to ${removalInfo.higherLevelEmail}`}
                </label>
                <label style={styles.radioLabel}>
                  <input
                    type="radio"
                    value="remove"
                    checked={removeOption === 'remove'}
                    onChange={() => setRemoveOption('remove')}
                  />
                  {` Also remove their ${removalInfo.inviteesCount} invitee${removalInfo.inviteesCount > 1 ? 's' : ''} from this project`}
                </label>
              </div>
            )}
            <div style={styles.modalButtons}>
              <button onClick={confirmRemovePermission} style={styles.confirmButton}>Yes, remove access</button>
              <button onClick={() => {
                setConfirmRemove(null);
                setRemovalInfo(null);
              }} style={styles.cancelButton}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareProjectScreen;
// src/pages/NewProjectContextScreen.js

import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { useUser } from '../UserContext';
import { Pencil } from 'lucide-react';

const NewProjectContextScreen = () => {
  const { projectId } = useParams();
  const [projectName, setProjectName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contextName, setContextName] = useState('');
  const [isEditingName, setIsEditingName] = useState(true);
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (!user.isAuthenticated) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/project/${projectId}/check-and-fetch?user_id=${user.user_id}`);
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch project');
        }

        const data = await response.json();
        setProjectName(data.project.name || 'Unnamed Project');
      } catch (err) {
        console.error('Error:', err);
        setError(err.message || 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectDetails();
  }, [projectId, user.isAuthenticated, user.user_id, navigate]);

  const handleNameChange = (e) => {
    setContextName(e.target.value);
  };

  const handleNameSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/project-context/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          project_id: projectId,
          name: contextName
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create context');
      }

      const data = await response.json();
      console.log('Context created:', data);
      navigate(`/project/${projectId}`);
    } catch (err) {
      console.error('Error creating context:', err);
      setError('Failed to create context. Please try again.');
    }
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/project/${projectId}`} style={styles.link}>
          &lt;&lt; Back to Project
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Add New Context to {projectName}
      </h1>
      
      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          <button
            onClick={() => setIsEditingName(!isEditingName)}
            style={{...styles.button, ...styles.editButton}}
          >
            <Pencil size={16} />
          </button>
          <label style={styles.label}>Context Name:</label>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          {isEditingName ? (
            <>
              <input
                value={contextName}
                onChange={handleNameChange}
                style={{...styles.formInput, textAlign: 'left'}}
                placeholder="Enter context name"
              />
              <button onClick={handleNameSave} style={{...styles.button, marginTop: '10px'}}>
                Save
              </button>
              <button onClick={() => navigate(`/project/${projectId}`)} style={{...styles.button, ...styles.cancelButton, marginTop: '10px', marginLeft: '10px'}}>
                Cancel
              </button>
            </>
          ) : (
            <p style={{...styles.projectName, textAlign: 'left'}}>
              {contextName || 'Unnamed Context'}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewProjectContextScreen;
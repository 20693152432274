// src/pages/CharacterVoiceScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Volume2 } from 'lucide-react';

const CharacterVoiceScreen = () => {
  const [character, setCharacter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);
  const [error, setError] = useState(null);
  const [voiceReferences, setVoiceReferences] = useState([]);
  const [selectedReference, setSelectedReference] = useState(null);
  const [referenceAudioUrl, setReferenceAudioUrl] = useState(null);
  const [generatedAudioUrl, setGeneratedAudioUrl] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [testWords, setTestWords] = useState("Hi there, I'm your new voice clone. Try your best to upload quality audio");
  const { user } = useUser();
  const { characterId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchCharacterDetails();
      fetchVoiceReferences();
    } else {
      navigate('/login');
    }
  }, [characterId, user.isAuthenticated, navigate]);

  useEffect(() => {
    const loadSavedSettings = async () => {
      if (!user.isAuthenticated || !characterId) return;

      console.log('Loading saved settings...');
      try {
        const response = await fetch(
          `${API_BASE_URL}/voice_gen/get-saved-settings/${characterId}?user_id=${user.user_id}`
        );
        
        if (response.ok) {
          const data = await response.json();
          console.log('Received saved settings:', data);
          
          if (data.settings) {
            const settings = data.settings;
            
            // Set the saved test words
            console.log('Setting saved test words:', settings.test_words);
            setTestWords(settings.test_words || '');
            
            // Set the saved generated audio
            console.log('Setting saved generated audio:', settings.output_url);
            setGeneratedAudioUrl(settings.output_url || null);
            
            // Load the reference independently since it requires a separate API call
            if (settings.reference_id) {
              console.log('Found saved reference ID:', settings.reference_id);
              // Wait for voice references to be loaded before setting the reference
              const savedReference = voiceReferences.find(ref => ref.id === settings.reference_id);
              if (savedReference) {
                console.log('Setting saved reference:', savedReference);
                setSelectedReference(savedReference);
                // Fetch the reference audio URL
                const audioResponse = await fetch(`${API_BASE_URL}/voice_gen/get-audio-url`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    user_id: user.user_id,
                    character_id: characterId,
                    reference_id: settings.reference_id,
                  }),
                });

                if (audioResponse.ok) {
                  const audioData = await audioResponse.json();
                  console.log('Setting reference audio URL:', audioData.audioUrl);
                  setReferenceAudioUrl(audioData.audioUrl);
                }
              }
            }
          }
        } else {
          console.log('No saved settings found or error occurred:', response.status);
        }
      } catch (err) {
        console.error('Error loading saved settings:', err);
      } finally {
        setIsLoadingSettings(false);
      }
    };

    // Only load settings after voice references are loaded
    if (voiceReferences.length > 0) {
      loadSavedSettings();
    }
  }, [voiceReferences, characterId, user.user_id, user.isAuthenticated]);

  const fetchCharacterDetails = async () => {
    if (!user.user_id || !characterId) return;

    try {
      const response = await fetch(`${API_BASE_URL}/character/${characterId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch character details');
      }

      const data = await response.json();
      setCharacter(data.character);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchVoiceReferences = async () => {
    if (!user.user_id || !characterId) return;

    try {
      const response = await fetch(
        `${API_BASE_URL}/voice/list-references?character_id=${characterId}&user_id=${user.user_id}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch voice references');
      }

      const data = await response.json();
      setVoiceReferences(data.voiceReferences || []);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    }
  };

  const handleReferenceSelect = async (reference) => {
    setSelectedReference(reference);
    setReferenceAudioUrl(null);

    try {
      const response = await fetch(`${API_BASE_URL}/voice_gen/get-audio-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          character_id: characterId,
          reference_id: reference.id,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to get audio URL');
      }

      const data = await response.json();
      setReferenceAudioUrl(data.audioUrl);
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to get reference audio URL');
    }
  };

  const handleTestWordsChange = (e) => {
    setTestWords(e.target.value);
  };

  const handleGenerateVoice = async () => {
    if (!referenceAudioUrl) return;

    setIsGenerating(true);
    setError(null);
    setGeneratedAudioUrl(null);

    try {
      const response = await fetch(`${API_BASE_URL}/voice_gen/generate-voice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          character_id: characterId,
          reference_url: referenceAudioUrl,
          reference_id: selectedReference.id,
          test_words: testWords,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate voice');
      }

      const data = await response.json();
      setGeneratedAudioUrl(data.generatedAudioUrl);
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to generate voice. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  if (isLoading || isLoadingSettings) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!character) return <div style={styles.container}>Character not found.</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/character/${characterId}`} style={styles.link}>
          &lt;&lt; Back to Character
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Voice Generation for {character.name}
      </h1>

      <div style={{...styles.formGroup, alignItems: 'flex-start'}}>
        <label style={{...styles.label, marginBottom: '10px'}}>Select Voice Reference:</label>
        {voiceReferences.length > 0 ? (
          <div style={{width: '100%'}}>
            {voiceReferences.map((reference) => (
              <div 
                key={reference.id}
                style={{
                  marginBottom: '10px',
                  padding: '10px',
                  backgroundColor: selectedReference?.id === reference.id ? 'rgba(0,123,255,0.1)' : 'transparent',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
                onClick={() => handleReferenceSelect(reference)}
              >
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                  <Volume2 size={16} />
                  <span>{reference.name}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p style={{textAlign: 'left'}}>No voice references available. Please add a voice reference first.</p>
        )}
      </div>

      {referenceAudioUrl && (
        <div style={{...styles.formGroup, marginTop: '20px'}}>
          <label style={{...styles.label, marginBottom: '10px'}}>Selected Reference Audio:</label>
          <audio
            controls
            style={{width: '100%'}}
            src={referenceAudioUrl}
          >
            Your browser does not support the audio element.
          </audio>
        </div>
      )}

      {referenceAudioUrl && (
        <div style={{...styles.formGroup, marginTop: '20px'}}>
          <label style={{...styles.label, marginBottom: '10px'}}>Words to Say:</label>
          <textarea
            value={testWords}
            onChange={handleTestWordsChange}
            style={{
              ...styles.textarea,
              minHeight: '80px',
              marginBottom: '15px'
            }}
            placeholder="Enter words to generate"
          />
        </div>
      )}

      {generatedAudioUrl && (
        <div style={{...styles.formGroup, marginTop: '20px'}}>
          <label style={{...styles.label, marginBottom: '10px'}}>Generated Voice:</label>
          <audio
            controls
            style={{width: '100%'}}
            src={generatedAudioUrl}
          >
            Your browser does not support the audio element.
          </audio>
        </div>
      )}

      <div style={styles.projectActions}>
        <button
          onClick={handleGenerateVoice}
          style={{
            ...styles.button,
            backgroundColor: referenceAudioUrl ? '#007BFF' : '#ccc',
            cursor: referenceAudioUrl ? 'pointer' : 'not-allowed',
          }}
          disabled={!referenceAudioUrl || isGenerating}
        >
          {isGenerating ? 'Generating Voice...' : 'Generate Voice'}
        </button>
      </div>
    </div>
  );
};

export default CharacterVoiceScreen;
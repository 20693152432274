// src/styles.js

const colors = {
  primary: '#007BFF',
  secondary: '#6C757D',
  accent: '#E50914',
  background: '#141414',
  text: '#FFFFFF',
  link: '#CCCCCC',
  linkHover: '#B0B0B0',
  error: '#E50914',
};

const typography = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  h1: { fontSize: '2.5rem', fontWeight: 700 },
  h2: { fontSize: '2rem', fontWeight: 600 },
  h3: { fontSize: '1.5rem', fontWeight: 500 },
  body: { fontSize: '1rem', lineHeight: 1.5 },
};

const spacing = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  xl: '32px',
};

const shadows = {
  sm: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  md: '0 4px 6px rgba(0,0,0,0.1)',
  lg: '0 10px 20px rgba(0,0,0,0.15), 0 3px 6px rgba(0,0,0,0.10)',
};

export const styles = {
  app: {
    textAlign: 'center',
    fontFamily: typography.fontFamily,
    color: colors.text,
    backgroundColor: colors.background,
  },
  navBar: {
    backgroundColor: colors.background,
    padding: '20px',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    boxShadow: shadows.sm,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoText: {
    color: colors.primary,
    fontSize: '2em',
    fontWeight: 'bold',
    margin: 0,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  navBarList: {
    listStyleType: 'none',
    padding: spacing.md,
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.sm,
    position: 'absolute',
    top: '60px',
    right: spacing.sm,
    backgroundColor: colors.secondary,
    borderRadius: '5px',
    width: 'auto',
    minWidth: '150px',
    boxShadow: shadows.md,
  },
  navBarItem: {
    display: 'block',
    width: '100%',
  },
  navBarLink: {
    ...typography.body,
    color: '#ffffff',
    textDecoration: 'none',
    padding: `${spacing.sm} ${spacing.md}`,
    borderRadius: '4px',
    transition: 'background-color 0.3s',
  },
  navBarRight: {
    display: 'flex',
    alignItems: 'center',
  },
  welcomeMessage: {
    color: '#ffffff',
    marginRight: '15px',
    fontSize: '0.9rem',
  },
  hamburgerIcon: {
    cursor: 'pointer',
    fontSize: '1.5em',
    color: '#ffffff',
  },
  mainContent: {
    marginTop: '80px',
    padding: '20px',
    backgroundColor: colors.background,
  },
  body: {
    margin: '0',
    fontFamily: typography.fontFamily,
    backgroundColor: colors.background,
  },
  code: {
    fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: `0 ${spacing.md}`,
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: '50px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing.xl,
    backgroundColor: colors.background,
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    boxShadow: 'none',
    borderRadius: '8px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: spacing.lg,
  },
  formGroup: {
    marginBottom: spacing.md,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  formInput: {
    padding: spacing.sm,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: `1px solid ${colors.secondary}`,
    width: '100%',
    transition: 'border-color 0.3s, box-shadow 0.3s',
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: colors.text,
  },
  eyeIcon: {
    position: 'absolute',
    right: spacing.sm,
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    color: colors.secondary,
  },
  label: {
    marginBottom: spacing.xs,
    fontSize: typography.body.fontSize,
    color: colors.text,
    alignSelf: 'flex-start',
  },
  textarea: {
    padding: spacing.sm,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: `1px solid ${colors.secondary}`,
    width: '100%',
    resize: 'vertical',
    minHeight: '100px',
    marginBottom: spacing.md,
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: colors.text,
  },
  button: {
    padding: `${spacing.sm} ${spacing.md}`,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: 'none',
    backgroundColor: colors.primary,
    color: '#ffffff',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  cancelButton: {
    padding: `${spacing.sm} ${spacing.md}`,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: 'none',
    backgroundColor: colors.secondary,
    color: '#ffffff',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  disabledButton: {
    backgroundColor: colors.secondary,
    cursor: 'not-allowed',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: spacing.md,
  },
  feedbackGrey: {
    color: 'grey',
    fontSize: '0.8rem',
    marginTop: '0.25rem',
  },
  feedbackGreen: {
    color: 'green',
    fontSize: '0.8rem',
    marginTop: '0.25rem',
  },
  feedbackRed: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: '0.25rem',
  },
  suggestionLink: {
    background: 'none',
    border: 'none',
    color: 'grey',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: 0,
    font: 'inherit',
  },
  link: {
    color: colors.link,
    textDecoration: 'none',
    transition: 'color 0.3s, text-decoration 0.3s',
  },
  sectionHeader: {
    ...typography.h2,
    color: colors.text,
    marginBottom: spacing.lg,
  },
  error: {
    color: colors.error,
    backgroundColor: 'rgba(229, 9, 20, 0.1)',
    padding: spacing.sm,
    marginTop: spacing.md,
    marginBottom: spacing.md,
    borderRadius: '4px',
    fontSize: '0.9rem',
    width: '100%',
    textAlign: 'center',
  },
  formTitle: {
    ...typography.h2,
    marginBottom: spacing.lg,
    color: colors.text,
  },
  accountDetails: {
    ...typography.body,
    color: colors.text,
    textAlign: 'left',
  },
  registrationContainer: {
    ...typography.body,
    color: colors.text,
    textAlign: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    padding: spacing.xl,
  },

  // Styles for ProjectListScreen
  projectName: {
    ...typography.body,
    color: colors.text,
    textAlign: 'left',
  },
  projectDescription: {
    ...typography.body,
    color: colors.text,
    textAlign: 'left',
  },
  projectActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: spacing.md,
    marginTop: spacing.lg,
  },
  createProjectButton: {
    display: 'inline-block', 
    marginBottom: '20px',
    textDecoration: 'none'
  },
  projectList: {
    listStyleType: 'none',
    padding: 0,
    textAlign: 'left',
  },
  viewProjectButton: {
    marginRight: '10px'
  },
  projectItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  subHeader: {
    fontSize: '1.2em',
    marginTop: '20px',
    marginBottom: '10px',
    textAlign: 'left',
  },
  ownerInfo: {
    fontSize: '0.8em',
    color: '#666',
    marginLeft: '10px',
  },

  // Styles for ProjectHomeScreen
  linkContainer: {
    textAlign: 'left',
    marginBottom: '20px'
  },
  editButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  editButton: {
    padding: '5px',
    marginRight: '10px'
  },

  // Story list styles for ProjectHomeScreen
  storiesList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Aligns flex items to the start of the container
    width: '100%', // Ensures the list takes full width of its container
  },
  storyItem: {
    marginBottom: '10px',
    paddingLeft: 0,
    width: '100%', // Ensures each item takes full width
  },
  storyLink: {
    color: colors.link,
    textDecoration: 'none',
    transition: 'color 0.3s',
    display: 'block',
    padding: '5px 0',
    width: '100%', // Ensures the link takes full width of its container
  },

  // Updated styles for ShareProjectScreen
  inviteContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  inviteInput: {
    width: '250px',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginRight: '10px',
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: colors.text,
  },
  permissionSelect: {
    padding: '8px',
    marginRight: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  inviteButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: colors.primary,
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  permissionsList: {
    listStyleType: 'none',
    padding: 0,
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    textAlign: 'left',
  },
  permissionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid #444',
  },
  permissionEmail: {
    color: '#fff',
    marginRight: '20px',
    minWidth: '200px',
  },
  permissionLevel: {
    color: '#aaa',
    fontStyle: 'italic',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    width: '100%',
    color: '#333333', // Ensure text in the modal is dark
  },
  modalText: {
    color: '#333333',
    fontSize: '16px',
    marginBottom: '20px',
  },
  radioGroup: {
    marginBottom: '20px',
  },
  radioLabel: {
    display: 'block',
    marginBottom: '10px',
    color: '#333333',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  confirmButton: {
    backgroundColor: '#ff4444',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    marginTop: '10px', // Add some space above the button
  },
  cancelButton: {
    backgroundColor: '#cccccc',
    color: '#333333',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    marginTop: '10px', // Add some space above the button
  },
  sharedBy: {
    fontSize: '0.8em',
    color: '#666',
    marginLeft: '10px',
  },
  table: {
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
    borderCollapse: 'collapse',
    backgroundColor: 'rgba(255,255,255,0.1)',
  },
  tableHeader: {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '2px solid #333',
    backgroundColor: '#444',
  },
  tableCell: {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #333',
  },
  tableSelect: {
    width: '100%',
    padding: '5px',
    backgroundColor: 'transparent',
    color: 'inherit',
    border: '1px solid #555',
  },
  tableContainer: {
    overflowX: 'auto',
    width: '100%',
  },

  // Length of story styles for StoryHomeScreen
  lengthSelectors: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.md,
  },
  lengthSelect: {
    padding: spacing.sm,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: `1px solid ${colors.secondary}`,
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: colors.text,
    cursor: 'pointer',
  },
  lengthLabel: {
    color: colors.text,
    fontSize: typography.body.fontSize,
  },


  // Updated styles for StoryLoglineScreen
  recommendationsGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '10px 15px',
    alignItems: 'start',
    width: '100%',
  },
  recommendationRow: {
    display: 'contents',
  },
  radioCell: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '3px', // Adjust this value to fine-tune vertical alignment
  },
  radioInput: {
    margin: 0,
  },
  recommendationText: {
    fontSize: '1rem',
    color: colors.text,
    textAlign: 'left',
    lineHeight: '1.4',
    display: 'block',
  },

};

export default styles;
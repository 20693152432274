// src/pages/ViewAttachmentScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const ViewAttachmentScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const { contextId } = useParams();
  const { user } = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const infoId = searchParams.get('infoId');
  const attachmentId = searchParams.get('attachmentId');

  useEffect(() => {
    if (user.isAuthenticated && contextId && infoId && attachmentId) {
      fetchAttachment();
    }
  }, [contextId, infoId, attachmentId, user.isAuthenticated]);

  const fetchAttachment = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/view-attachment/${contextId}/information/${infoId}/attachment/${attachmentId}?user_id=${user.user_id}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch attachment');
      }

      const data = await response.json();
      setAttachment(data.attachment);
      setIsLoading(false);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
      setIsLoading(false);
    }
  };

  const renderPdfViewer = () => {
    if (attachment?.type === 'pdf' && attachment?.content) {
      return (
        <div style={{
          width: '100%',
          height: '800px',
          marginTop: '20px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          overflow: 'hidden'
        }}>
          <iframe
            src={`data:application/pdf;base64,${attachment.content}`}
            style={{
              width: '100%',
              height: '100%',
              border: 'none'
            }}
            title="PDF Viewer"
          />
        </div>
      );
    }
    return null;
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!attachment) return <div style={styles.container}>Attachment not found.</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link 
          to={`/context-information/${contextId}?infoId=${infoId}`} 
          style={styles.link}
        >
          &lt;&lt; Back to Information
        </Link>
      </div>

      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        View Attachment
      </h1>

      <div style={styles.formGroup}>
        <label style={styles.label}>File Name:</label>
        <p style={{...styles.projectName, textAlign: 'left'}}>
          {attachment.name}
        </p>
      </div>

      {/* PDF Viewer Section */}
      {renderPdfViewer()}

      {!renderPdfViewer() && (
        <div style={{
          marginTop: '20px',
          padding: '20px',
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: '4px',
          textAlign: 'center'
        }}>
          <p>Preview not available for this file type.</p>
        </div>
      )}
    </div>
  );
};

export default ViewAttachmentScreen;
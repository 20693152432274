// src/pages/ProjectListScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const ProjectListScreen = () => {
  const [ownedProjects, setOwnedProjects] = useState([]);
  const [accessibleProjects, setAccessibleProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, [user.user_id]);

  const fetchProjects = async () => {
    if (!user.user_id || !user.email) return;

    try {
      const response = await fetch(`${API_BASE_URL}/project/list-all?user_id=${user.user_id}&user_email=${user.email}`);
      if (!response.ok) {
        throw new Error('Failed to fetch projects');
      }
      const data = await response.json();
      setOwnedProjects(data.owned_projects);
      setAccessibleProjects(data.accessible_projects);
    } catch (err) {
      setError('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderProjectList = (projects, title) => (
    <>
      <h2 style={{...styles.subHeader, textAlign: 'left'}}>{title}</h2>
      {projects.length === 0 ? (
        <p>No projects found.</p>
      ) : (
        <ul style={styles.projectList}>
          {projects.map(project => (
            <li key={project.id} style={styles.projectItem}>
              <button 
                style={{...styles.button, ...styles.viewProjectButton}}
                onClick={() => navigate(`/project/${project.id}`)}
              >
                View
              </button>
              <span>{project.name}</span>
              {title === "Projects I Can Access" && (
                <span style={styles.ownerInfo}>
                  Owner: {project.owner} | Permission: {project.permission}
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );

  if (isLoading) return <div style={styles.container}>Loading projects...</div>;
  if (error) return <div style={styles.container}>{error}</div>;

  return (
    <div style={styles.container}>
      <h1 style={styles.formTitle}>My Projects</h1>
      <Link 
        to="/createproject" 
        style={{...styles.button, ...styles.createProjectButton}}
      >
        Create Project
      </Link>
      {renderProjectList(ownedProjects, "Projects I Own")}
      {renderProjectList(accessibleProjects, "Projects I Can Access")}
    </div>
  );
};

export default ProjectListScreen;
// src/pages/StoryLoglineScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const StoryLoglineScreen = () => {
  const [story, setStory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logline, setLogline] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [selectedRecommendation, setSelectedRecommendation] = useState('');
  const [feedback, setFeedback] = useState('');
  const [generationStep, setGenerationStep] = useState('');
  const { user } = useUser();
  const { storyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchStoryDetails();
      fetchExistingRecommendations();
    } else {
      navigate('/login');
    }
  }, [storyId, user.isAuthenticated, navigate]);

  const fetchStoryDetails = async () => {
    if (!user.user_id || !storyId) return;

    try {
      const response = await fetch(`${API_BASE_URL}/story/${storyId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch story details');
      }

      const data = await response.json();
      setStory(data.story);
      setLogline(data.story.logline?.text || '');
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to fetch story details');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExistingRecommendations = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_BASE_URL}/logline/get-existing-recommendations?user_id=${user.user_id}&story_id=${storyId}`);
      
      if (!response.ok) {
        if (response.status === 404) {
          await fetchRecommendations();
        } else {
          throw new Error('Failed to fetch existing recommendations');
        }
      } else {
        const data = await response.json();
        const parsedRecommendations = parseRecommendations(data.recommendations);
        setRecommendations(parsedRecommendations);
        setFeedback(data.feedback || '');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to fetch recommendations');
    } finally {
      setIsLoading(false);
      setGenerationStep('');
    }
  };

  const parseRecommendations = (recommendationsString) => {
    const regex = /\d+\.\s(.+)/g;
    const matches = [...recommendationsString.matchAll(regex)];
    return matches.map(match => match[1].trim());
  };

  const fetchRecommendations = async () => {
    try {
      setGenerationStep('Analyzing project context...');
      const contextResponse = await fetch(`${API_BASE_URL}/logline/generate-logline-context`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId,
        }),
      });

      if (!contextResponse.ok) {
        throw new Error('Failed to generate logline context');
      }

      setGenerationStep('Generating logline recommendations...');
      const response = await fetch(`${API_BASE_URL}/logline/generate-recommendations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch recommendations');
      }

      const data = await response.json();
      const parsedRecommendations = parseRecommendations(data.recommendations);
      setRecommendations(parsedRecommendations);
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to generate recommendations');
      setRecommendations([]);
    }
  };

  const handleLoglineChange = (e) => {
    setLogline(e.target.value);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleRecommendationSelect = (e) => {
    setSelectedRecommendation(e.target.value);
  };

  const handleApplySelected = () => {
    if (selectedRecommendation) {
      setLogline(selectedRecommendation);
    }
  };

  const handleLoglineSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/logline/edit-logline`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId,
          logline 
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update logline');
      }

      navigate(`/story/${storyId}`);
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to save logline');
    }
  };

  const handleUpdateRecommendations = async () => {
    try {
      setIsLoading(true);
      // Clear existing recommendations immediately
      setRecommendations([]);
      setGenerationStep('Submitting feedback...');

      const feedbackResponse = await fetch(`${API_BASE_URL}/logline/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId,
          feedback 
        }),
      });

      if (!feedbackResponse.ok) {
        throw new Error('Failed to submit feedback');
      }

      setGenerationStep('Updating recommendations...');
      const refreshResponse = await fetch(`${API_BASE_URL}/logline/refresh-recommendation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId
        }),
      });

      if (!refreshResponse.ok) {
        throw new Error('Failed to refresh recommendations');
      }

      const data = await refreshResponse.json();
      const parsedRecommendations = parseRecommendations(data.recommendations);
      setRecommendations(parsedRecommendations);
      setFeedback('');
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to update recommendations');
    } finally {
      setIsLoading(false);
      setGenerationStep('');
    }
  };

  const handleReset = async () => {
    try {
      setIsLoading(true);
      setFeedback('');
      // Clear existing recommendations immediately
      setRecommendations([]);
      await fetchRecommendations();
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to reset recommendations');
    } finally {
      setIsLoading(false);
      setGenerationStep('');
    }
  };

  if (error) return <div style={styles.container}>{error}</div>;
  if (!story) return <div style={styles.container}>Story not found.</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/story/${storyId}`} style={styles.link}>
          &lt;&lt; Back to Story
        </Link>
      </div>

      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Edit Logline for {story.title}
      </h1>

      {isLoading && (
        <div style={styles.formGroup}>
          <p style={{color: '#fff', textAlign: 'center'}}>
            {generationStep || 'Loading...'}
          </p>
        </div>
      )}

      {recommendations.length > 0 && (
        <div style={styles.formGroup}>
          <label style={styles.label}>Logline Recommendations:</label>
          <div style={styles.recommendationsGrid}>
            {recommendations.map((rec, index) => (
              <div key={index} style={styles.recommendationRow}>
                <div style={styles.radioCell}>
                  <input
                    type="radio"
                    id={`rec${index}`}
                    name="recommendation"
                    value={rec}
                    checked={selectedRecommendation === rec}
                    onChange={handleRecommendationSelect}
                    style={styles.radioInput}
                  />
                </div>
                <label htmlFor={`rec${index}`} style={styles.recommendationText}>
                  {rec}
                </label>
              </div>
            ))}
          </div>
          <button 
            onClick={handleApplySelected} 
            style={{...styles.button, marginTop: '10px'}}
            disabled={isLoading}
          >
            Apply Selected
          </button>
        </div>
      )}

      <div style={{...styles.formGroup, marginBottom: '10px'}}>
        <label style={styles.label}>User Feedback on Recommendations:</label>
        <textarea
          value={feedback}
          onChange={handleFeedbackChange}
          style={{...styles.textarea, textAlign: 'left', marginBottom: '10px'}}
          placeholder="Enter your feedback on the recommendations"
        />
        <div style={{...styles.projectActions, marginBottom: '20px'}}>
          <button 
            onClick={handleUpdateRecommendations} 
            style={{...styles.button, backgroundColor: '#007BFF', marginRight: '10px'}}
            disabled={isLoading}
          >
            Update Recommendations
          </button>
          <button 
            onClick={handleReset} 
            style={{...styles.button, backgroundColor: '#ff4444'}}
            disabled={isLoading}
          >
            Reset
          </button>
        </div>
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Current Logline:</label>
        <textarea
          value={logline}
          onChange={handleLoglineChange}
          style={{...styles.textarea, textAlign: 'left', marginBottom: '10px'}}
          placeholder="Enter story logline"
        />
        <button 
          onClick={handleLoglineSave} 
          style={styles.button}
          disabled={isLoading}
        >
          Save Logline
        </button>
      </div>
    </div>
  );
};

export default StoryLoglineScreen;
// src/pages/ProjectContextHomeScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Pencil, PlusCircle } from 'lucide-react';

const ProjectContextHomeScreen = () => {
  const [contextItem, setContextItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [name, setName] = useState('');
  const [contextType, setContextType] = useState('Uncategorized');
  const [permissionLevel, setPermissionLevel] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [contextInformation, setContextInformation] = useState([]);
  const { user } = useUser();
  const { contextId } = useParams();
  const navigate = useNavigate();

  const contextTypes = [
    'Character',
    'Plot',
    'Setting',
    'Style',
    'Theme',
    'Uncategorized'
  ];

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchContextDetails();
      fetchContextInformation();
    } else {
      navigate('/login');
    }
  }, [contextId, user.isAuthenticated, navigate]);

  const fetchContextDetails = async () => {
    if (!user.user_id || !contextId) return;

    try {
      const response = await fetch(`${API_BASE_URL}/project-context/${contextId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch context details');
      }

      const data = await response.json();
      setContextItem(data.contextItem);
      setPermissionLevel(data.permissionLevel);
      setProjectId(data.projectId);
      setName(data.contextItem.name || '');
      setContextType(data.contextItem.contextType || 'Uncategorized');
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContextInformation = async () => {
    if (!user.user_id || !contextId) return;

    try {
      const response = await fetch(`${API_BASE_URL}/project-context/${contextId}/information?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch context information');
      }

      const data = await response.json();
      setContextInformation(data.information || []);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleTypeChange = async (e) => {
    const newType = e.target.value;
    try {
      const response = await fetch(`${API_BASE_URL}/project-context/${contextId}/update-type`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          context_type: newType 
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update type');
      }

      setContextType(newType);
      setContextItem({ ...contextItem, contextType: newType });
    } catch (err) {
      console.error('Error updating type:', err);
      setError('Failed to update type. Please try again.');
    }
  };

  const handleNameSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/project-context/${contextId}/update-name`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          name 
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update name');
      }

      setContextItem({ ...contextItem, name });
      setIsEditingName(false);
    } catch (err) {
      console.error('Error updating name:', err);
      setError('Failed to update name. Please try again.');
    }
  };

  const handleDeleteContext = async () => {
    if (window.confirm('Are you sure you want to delete this context item?')) {
      try {
        const response = await fetch(`${API_BASE_URL}/project-context/delete`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            user_id: user.user_id, 
            context_id: contextId,
            project_id: projectId
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to delete context item');
        }

        navigate(`/project/${projectId}`);
      } catch (err) {
        console.error('Error deleting context:', err);
        setError('Failed to delete context item. Please try again.');
      }
    }
  };

  if (isLoading) return <div style={styles.container}>Loading context...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!contextItem) return <div style={styles.container}>Context item not found.</div>;

  const canEdit = permissionLevel === 'owner' || permissionLevel === 'editor';

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/project/${projectId}`} style={styles.link}>
          &lt;&lt; Back to Project Home
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        {contextItem.name || 'Unnamed Context'}
      </h1>

      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          {canEdit && (
            <button
              onClick={() => setIsEditingName(!isEditingName)}
              style={{...styles.button, ...styles.editButton}}
            >
              <Pencil size={16} />
            </button>
          )}
          <label style={styles.label}>Context Name:</label>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          {isEditingName && canEdit ? (
            <>
              <input
                value={name}
                onChange={handleNameChange}
                style={{...styles.formInput, textAlign: 'left'}}
                placeholder="Enter context name"
              />
              <button onClick={handleNameSave} style={{...styles.button, marginTop: '10px'}}>
                Save
              </button>
            </>
          ) : (
            <p style={{...styles.projectName, textAlign: 'left'}}>
              {contextItem.name || 'Unnamed Context'}
            </p>
          )}
        </div>
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Context Type:</label>
        {canEdit ? (
          <select 
            value={contextType}
            onChange={handleTypeChange}
            style={{...styles.permissionSelect, marginTop: '5px'}}
          >
            {contextTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        ) : (
          <p style={{...styles.projectName, textAlign: 'left'}}>
            {contextType}
          </p>
        )}
      </div>

      <div style={{...styles.formGroup, alignItems: 'flex-start'}}>
        <label style={{...styles.label, marginBottom: '10px'}}>Context Information:</label>
        {contextInformation.length > 0 ? (
          <ul style={{
            ...styles.storiesList,
            padding: 0,
            margin: 0,
            listStyleType: 'none',
          }}>
            {contextInformation.map((info) => (
              <li key={info.id} style={{
                marginBottom: '10px',
              }}>
                <Link 
                  to={`/context-information/${contextId}?infoId=${info.id}`} 
                  style={{
                    ...styles.storyLink,
                    display: 'block',
                    textAlign: 'left',
                  }}
                >
                  {info.name}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p style={{textAlign: 'left'}}>No information added yet.</p>
        )}
      </div>

      {canEdit && (
        <div style={{...styles.formGroup, marginTop: '20px'}}>
          <Link 
            to={`/context-information/${contextId}`} 
            style={{
              ...styles.link,
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#007BFF',
            }}
          >
            <PlusCircle size={24} style={{marginRight: '10px'}} />
            <span>Add or Upload Information</span>
          </Link>
        </div>
      )}

      {canEdit && (
        <div style={styles.projectActions}>
          <button 
            style={{...styles.button, backgroundColor: '#ff4444'}}
            onClick={handleDeleteContext}
          >
            Delete Context Item
          </button>
        </div>
      )}
    </div>
  );
};

export default ProjectContextHomeScreen;
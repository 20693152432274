// src/pages/StoryScriptScreen.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const StoryScriptScreen = () => {
  const [story, setStory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [script, setScript] = useState('');
  const [aiRecommendation, setAiRecommendation] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationStep, setGenerationStep] = useState(''); // Track current generation step
  const { user } = useUser();
  const { storyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchStoryDetails();
    } else {
      navigate('/login');
    }
  }, [storyId, user.isAuthenticated, navigate]);

  const fetchStoryDetails = async () => {
    if (!user.user_id || !storyId) return;

    try {
      const response = await fetch(`${API_BASE_URL}/story/${storyId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch story details');
      }

      const data = await response.json();
      setStory(data.story);
      setScript(data.story.script?.text || '');
      
      // Always try to fetch existing recommendations
      await fetchExistingRecommendation();
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExistingRecommendation = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/script/get-recommendation?user_id=${user.user_id}&story_id=${storyId}`);
      
      if (response.ok) {
        const data = await response.json();
        setAiRecommendation(data.recommendation);
        setFeedback(data.feedback || '');
      } else if (response.status === 404) {
        // Only generate new recommendation if none exists
        await generateScriptRecommendation();
      } else {
        throw new Error('Failed to fetch recommendation');
      }
    } catch (err) {
      console.error('Error fetching recommendation:', err);
      setError('Failed to fetch recommendation. Please try again.');
    }
  };

  const generateScriptRecommendation = async () => {
    try {
      setIsGenerating(true);

      // First, generate the context
      setGenerationStep('Analyzing project context...');
      const contextResponse = await fetch(`${API_BASE_URL}/script/generate-script-context`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId,
        }),
      });

      if (!contextResponse.ok) {
        throw new Error('Failed to generate script context');
      }

      // Then generate the script recommendation
      setGenerationStep('Generating script recommendation...');
      const response = await fetch(`${API_BASE_URL}/script/generate-script-recommendation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate script recommendation');
      }

      const data = await response.json();
      setAiRecommendation(data.recommendation);
      setGenerationStep('');
    } catch (err) {
      console.error('Error generating script recommendation:', err);
      setError('Failed to generate script recommendation. Please try again.');
      setGenerationStep('');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleScriptChange = (e) => {
    setScript(e.target.value);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleUpdateRecommendation = async () => {
    try {
      setIsGenerating(true);

      // Submit feedback first
      setGenerationStep('Submitting feedback...');
      const feedbackResponse = await fetch(`${API_BASE_URL}/script/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId,
          feedback 
        }),
      });

      if (!feedbackResponse.ok) {
        throw new Error('Failed to submit feedback');
      }

      // Generate new recommendation based on feedback
      setGenerationStep('Updating recommendation...');
      const refreshResponse = await fetch(`${API_BASE_URL}/script/refresh-recommendation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId
        }),
      });

      if (!refreshResponse.ok) {
        throw new Error('Failed to refresh recommendation');
      }

      const data = await refreshResponse.json();
      setAiRecommendation(data.recommendation);
      setFeedback('');
      setGenerationStep('');
    } catch (err) {
      console.error('Error updating recommendation:', err);
      setError('Failed to update recommendation. Please try again.');
      setGenerationStep('');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleReset = async () => {
    try {
      setIsGenerating(true);
      setFeedback('');
      await generateScriptRecommendation();
    } catch (err) {
      console.error('Error resetting recommendation:', err);
      setError('Failed to reset recommendation. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleScriptSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/script/edit-script`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          story_id: storyId,
          script 
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update script');
      }

      navigate(`/story/${storyId}`);
    } catch (err) {
      console.error('Error updating script:', err);
      setError('Failed to update script. Please try again.');
    }
  };

  const handleUseRecommendation = () => {
    setScript(aiRecommendation);
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!story) return <div style={styles.container}>Story not found.</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/story/${storyId}`} style={styles.link}>
          &lt;&lt; Back to Story
        </Link>
      </div>

      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Edit Script for {story.title}
      </h1>

      {aiRecommendation && (
        <div style={styles.formGroup}>
          <label style={styles.label}>Script Recommendation:</label>
          <div style={{
            ...styles.textarea,
            backgroundColor: 'rgba(255,255,255,0.05)',
            padding: '15px',
            whiteSpace: 'pre-wrap',
            marginBottom: '15px'
          }}>
            {aiRecommendation}
          </div>
          <button 
            onClick={handleUseRecommendation} 
            style={{...styles.button, marginBottom: '20px'}}
          >
            Use This Script
          </button>
          <div style={{...styles.formGroup, marginBottom: '10px'}}>
            <label style={styles.label}>User Feedback on Recommendation:</label>
            <textarea
              value={feedback}
              onChange={handleFeedbackChange}
              style={{...styles.textarea, textAlign: 'left', marginBottom: '10px'}}
              placeholder="Enter your feedback on the recommendation"
            />
            <div style={{...styles.projectActions, marginBottom: '20px'}}>
              <button 
                onClick={handleUpdateRecommendation} 
                style={{...styles.button, backgroundColor: '#007BFF', marginRight: '10px'}}
                disabled={isGenerating}
              >
                Update Recommendation
              </button>
              <button 
                onClick={handleReset} 
                style={{...styles.button, backgroundColor: '#ff4444'}}
                disabled={isGenerating}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}

      {isGenerating && (
        <div style={styles.formGroup}>
          <p style={{color: '#fff', textAlign: 'center'}}>
            {generationStep || 'Generating script recommendation...'}
          </p>
        </div>
      )}

      <div style={styles.formGroup}>
        <label style={styles.label}>Current Script:</label>
        <textarea
          value={script}
          onChange={handleScriptChange}
          style={{...styles.textarea, textAlign: 'left', marginBottom: '10px'}}
          placeholder="Enter story script"
        />
        <button 
          onClick={handleScriptSave} 
          style={styles.button}
          disabled={isGenerating}
        >
          Save Script
        </button>
      </div>
    </div>
  );
};

export default StoryScriptScreen;
// src/pages/VoiceReferenceHomeScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Pencil, Volume2 } from 'lucide-react';

const VoiceReferenceHomeScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [isEditingName, setIsEditingName] = useState(true);
  const [reference, setReference] = useState(null);
  const [characterName, setCharacterName] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [urlError, setUrlError] = useState('');
  const [timeError, setTimeError] = useState('');
  const [audioUrl, setAudioUrl] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRefreshingAudio, setIsRefreshingAudio] = useState(false);
  const { characterId, referenceId } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchCharacterDetails();
      if (referenceId) {
        fetchReferenceDetails();
      } else {
        setIsLoading(false);
      }
    } else {
      navigate('/login');
    }
  }, [characterId, referenceId, user.isAuthenticated, navigate]);

  useEffect(() => {
    let refreshInterval;

    if (reference?.audioPath && !audioUrl && !isRefreshingAudio) {
      refreshAudioUrl();
    }

    if (audioUrl) {
      refreshInterval = setInterval(refreshAudioUrl, 10 * 60 * 1000);
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [reference?.audioPath, audioUrl, isRefreshingAudio]);

  const fetchCharacterDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/character/${characterId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch character details');
      }

      const data = await response.json();
      setCharacterName(data.character.name || 'Unnamed Character');
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    }
  };

  const fetchReferenceDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/voice/reference/${characterId}/${referenceId}?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch voice reference details');
      }

      const data = await response.json();
      setReference(data.reference);
      setName(data.reference.name || '');
      setYoutubeUrl(data.reference.youtubeUrl || '');
      setStartTime(data.reference.startTime || '00:00');
      setEndTime(data.reference.endTime || '00:00');
      
      if (data.reference.signedUrl) {
        setAudioUrl(data.reference.signedUrl);
      } else if (data.reference.audioPath) {
        console.log('Audio file exists but URL needs to be refreshed');
      } else {
        setAudioUrl(null);
      }
      
      setIsEditingName(false);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const refreshAudioUrl = async () => {
    if (isRefreshingAudio) return;

    try {
      setIsRefreshingAudio(true);
      const response = await fetch(`${API_BASE_URL}/voice/reference/${characterId}/${referenceId}?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to refresh audio URL');
      }

      const data = await response.json();
      if (data.reference.signedUrl) {
        setAudioUrl(data.reference.signedUrl);
      }
    } catch (err) {
      console.error('Error refreshing audio URL:', err);
      setError('Failed to refresh audio URL. Please try reloading the page.');
    } finally {
      setIsRefreshingAudio(false);
    }
  };

  const validateYoutubeUrl = (url) => {
    if (!url) return true; // Empty URL is valid
    // Updated regex to handle additional parameters
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=[a-zA-Z0-9_-]{11}|youtu\.be\/[a-zA-Z0-9_-]{11})([\?&][^?&]+)*$/;
    return youtubeRegex.test(url);
  };

  const validateTimeFormat = (time) => {
    const timeRegex = /^([0-9][0-9]):([0-5][0-9])$/;
    return timeRegex.test(time);
  };

  const convertTimeToSeconds = (timeString) => {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return minutes * 60 + seconds;
  };

  const validateTimes = (start, end) => {
    if (!validateTimeFormat(start) || !validateTimeFormat(end)) {
      setTimeError('Invalid time format');
      return false;
    }
    
    const startSeconds = convertTimeToSeconds(start);
    const endSeconds = convertTimeToSeconds(end);
    
    if (endSeconds <= startSeconds) {
      setTimeError('End time must be after start time');
      return false;
    }
    
    const duration = endSeconds - startSeconds;
    if (duration > 180) { // 180 seconds = 3 minutes
      setTimeError('Maximum clip duration is 3 minutes');
      return false;
    }
    
    setTimeError('');
    return true;
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleUrlChange = (e) => {
    const url = e.target.value;
    setYoutubeUrl(url);
    if (url && !validateYoutubeUrl(url)) {
      setUrlError('Please enter a valid YouTube URL');
    } else {
      setUrlError('');
    }
  };

  const handleStartTimeChange = (e) => {
    const time = e.target.value;
    setStartTime(time);
    if (time && endTime) {
      validateTimes(time, endTime);
    } else {
      setTimeError('');
    }
  };

  const handleEndTimeChange = (e) => {
    const time = e.target.value;
    setEndTime(time);
    if (startTime && time) {
      validateTimes(startTime, time);
    } else {
      setTimeError('');
    }
  };

  const handleAudioError = () => {
    console.log('Audio playback error - attempting to refresh URL');
    refreshAudioUrl();
  };

  const handleSave = async () => {
    if (!name.trim()) {
      setError('Please enter a name for the voice reference');
      return;
    }

    if (youtubeUrl && !validateYoutubeUrl(youtubeUrl)) {
      setUrlError('Please enter a valid YouTube URL');
      return;
    }

    if ((startTime !== '00:00' || endTime !== '00:00')) {
      if (!validateTimes(startTime, endTime)) {
        return; // validateTimes sets the appropriate error message
      }
    }

    setError(null);
    setIsSaving(true);

    try {
      const endpoint = referenceId ? 
        `${API_BASE_URL}/voice/update-reference/${referenceId}` :
        `${API_BASE_URL}/voice/create-voice-reference`;
      
      const response = await fetch(endpoint, {
        method: referenceId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          character_id: characterId,
          name,
          youtubeUrl,
          startTime,
          endTime
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save voice reference');
      }

      const data = await response.json();
      const savedReferenceId = referenceId || data.reference_id;

      if (youtubeUrl && startTime && endTime) {
        setIsProcessing(true);
        const processResponse = await fetch(`${API_BASE_URL}/voice/process-audio/${savedReferenceId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: user.user_id,
            character_id: characterId,
            youtubeUrl,
            startTime,
            endTime
          }),
        });

        if (!processResponse.ok) {
          throw new Error('Failed to process audio');
        }

        const processData = await processResponse.json();
        setAudioUrl(processData.signedUrl);
      }

      if (!referenceId) {
        navigate(`/voice-reference/${characterId}/${savedReferenceId}`);
      } else {
        setReference({
          ...reference,
          name,
          youtubeUrl,
          startTime,
          endTime
        });
        setIsEditingName(false);
        setSaveSuccess(true);
        setTimeout(() => {
          setSaveSuccess(false);
        }, 3000);
      }
    } catch (err) {
      console.error('Error saving voice reference:', err);
      setError('Failed to save voice reference. Please try again.');
    } finally {
      setIsSaving(false);
      setIsProcessing(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this voice reference?')) {
      return;
    }

    setIsDeleting(true);
    try {
      const response = await fetch(`${API_BASE_URL}/voice/delete-reference`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          character_id: characterId,
          reference_id: referenceId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete voice reference');
      }

      navigate(`/character/${characterId}`);
    } catch (err) {
      console.error('Error deleting voice reference:', err);
      setError('Failed to delete voice reference. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/character/${characterId}`} style={styles.link}>
          &lt;&lt; Back to Character
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        {referenceId ? name || 'Unnamed Voice Reference' : 'Add Voice Reference'}
      </h1>

      <div style={styles.formGroup}>
        <label style={styles.label}>Voice Reference Name:</label>
        <input
          value={name}
          onChange={handleNameChange}
          style={{...styles.formInput, textAlign: 'left'}}
          placeholder="Enter voice reference name"
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>YouTube URL:</label>
        <input
          value={youtubeUrl}
          onChange={handleUrlChange}
          style={{...styles.formInput, textAlign: 'left'}}
          placeholder="Enter YouTube URL"
        />
        {urlError && <div style={{...styles.error, marginTop: '5px'}}>{urlError}</div>}
      </div>

      <div style={{...styles.formGroup, display: 'flex', flexDirection: 'row', gap: '20px'}}>
        <div style={{flex: 1}}>
          <label style={styles.label}>Start Time (MM:SS):</label>
          <input
            value={startTime}
            onChange={handleStartTimeChange}
            style={{...styles.formInput, textAlign: 'left'}}
            placeholder="00:00"
          />
        </div>
        <div style={{flex: 1}}>
          <label style={styles.label}>End Time (MM:SS):</label>
          <input
            value={endTime}
            onChange={handleEndTimeChange}
            style={{...styles.formInput, textAlign: 'left'}}
            placeholder="00:00"
          />
        </div>
      </div>
      {timeError && <div style={{...styles.error, marginTop: '5px'}}>{timeError}</div>}
      <div style={{
        color: '#666',
        fontSize: '0.8rem',
        marginTop: '5px',
        fontStyle: 'italic'
      }}>
        Maximum clip duration: 3 minutes
      </div>

      {audioUrl && (
        <div style={{...styles.formGroup, marginTop: '20px'}}>
          <label style={styles.label}>
            <Volume2 size={16} style={{marginRight: '8px'}} />
            Audio Preview:
          </label>
          <audio
            controls
            style={{width: '100%', marginTop: '10px'}}
            src={audioUrl}
            onError={handleAudioError}
          >
            Your browser does not support the audio element.
          </audio>
        </div>
      )}

      {isProcessing && (
        <div style={{...styles.formGroup, marginTop: '20px'}}>
          <p style={{color: '#666', textAlign: 'center'}}>
            Processing audio... This may take a few moments.
          </p>
        </div>
      )}

      <div style={{...styles.formGroup, marginTop: '20px'}}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <button 
            onClick={handleSave} 
            style={{
              ...styles.button,
              backgroundColor: isSaving ? '#cccccc' : '#007BFF',
              cursor: isSaving ? 'not-allowed' : 'pointer',
              transition: 'background-color 0.3s ease',
            }}
            disabled={!name.trim() || urlError || timeError || isSaving}
          >
            {isSaving ? 'Saving...' : 'Save Reference'}
            </button>
            {saveSuccess && (
              <span style={{
                color: '#28a745',
                fontSize: '0.9rem',
                opacity: 1,
                transition: 'opacity 0.3s ease',
              }}>
                Changes saved successfully
              </span>
            )}
          </div>
  
          {referenceId ? (
            <button 
              onClick={handleDelete} 
              style={{
                ...styles.button,
                backgroundColor: isDeleting ? '#cccccc' : '#ff4444',
                cursor: isDeleting ? 'not-allowed' : 'pointer',
                transition: 'background-color 0.3s ease',
                marginTop: '10px'
              }}
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete Reference'}
            </button>
          ) : (
            <button 
              onClick={() => navigate(`/character/${characterId}`)} 
              style={{...styles.button, ...styles.cancelButton, marginTop: '10px'}}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    );
  };
  
  export default VoiceReferenceHomeScreen;